import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { expValEquals } from '@confluence/feature-experiments';

import { CatchupLastViewedTimeQuery } from '../CatchupLastViewedTimeQuery.graphql';
import type {
	CatchupContentType,
	CatchupLastViewedTimeQuery as CatchupLastViewedTimeQueryResponse,
	CatchupLastViewedTimeQueryVariables,
} from '../__types__/CatchupLastViewedTimeQuery';

type DynamicSplitButtonReturnType = {
	showCatchupAsDefault: boolean;
	queryLoading: boolean;
	queryError?: ApolloError | undefined;
};

export const useDynamicSplitButton = (
	contentId: string,
	contentType: CatchupContentType,
	lastModifiedDate: string,
): DynamicSplitButtonReturnType => {
	const {
		data,
		loading: queryLoading,
		error: queryError,
	} = useQuery<CatchupLastViewedTimeQueryResponse, CatchupLastViewedTimeQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CatchupLastViewedTimeQuery,
		{
			variables: {
				contentId,
				contentType,
			},
			skip: !expValEquals('cc_ai_consumption_ux_button', 'button-placement', 'bottom'),
		},
	);

	const lastViewedTime = data?.catchupGetLastViewedTime?.lastViewedTimeISO ?? '';
	const parsedLastViewedTime = Date.parse(lastViewedTime);
	const parsedLastModifiedDate = Date.parse(lastModifiedDate);

	if (!parsedLastViewedTime || !parsedLastModifiedDate || queryError) {
		return {
			showCatchupAsDefault: false,
			queryLoading,
			queryError,
		};
	}

	const showCatchupAsDefault =
		parsedLastModifiedDate > parsedLastViewedTime &&
		expValEquals('cc_ai_consumption_ux_button', 'button-placement', 'bottom');

	return {
		showCatchupAsDefault,
		queryLoading,
	};
};
