import React, { type FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button, { IconButton } from '@atlaskit/button/new';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import type { TriggerProps } from '@atlaskit/popup';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';

import { AtlassianIntelligenceLogoDark } from '@atlassian/ai-components';

const i18n = defineMessages({
	logoAltText: {
		id: 'ai-floating-context-menu.floating-context-menu-button.logo-alt-text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'The alt text for the Atlassian Intelligence logo icon',
	},
});

const aiFloatingContextMenuStyles = xcss({
	borderRadius: 'border.radius.300',
	padding: 'space.075',
	boxShadow: 'elevation.shadow.overlay',
	backgroundColor: 'elevation.surface.raised',
});

const aiFloatingContextMenuStylesInObjectSidebar = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: 'border.radius.circle',
});

const textColorStyles = xcss({
	color: 'color.text.subtle',
});

const dividerStyles = xcss({
	height: '100%',
	width: '1px',
	backgroundColor: 'color.background.neutral',
});

const dividerStylesInObjectSidebar = xcss({
	height: '24px',
	width: '1px',
	backgroundColor: 'color.background.neutral',
});

type AIFloatingContextMenuButtonProps = {
	dynamicActionText?: string;
	isDynamicActionSelected?: boolean;
	onDynamicActionClick?: () => void;
	onMenuButtonClick?: () => void;
	triggerProps?: TriggerProps;
	isMenuOpen?: boolean;
	isInObjectSideBar?: boolean;
};

export const AIFloatingSplitButton: FC<AIFloatingContextMenuButtonProps> = ({
	dynamicActionText,
	isDynamicActionSelected = false,
	onDynamicActionClick,
	onMenuButtonClick,
	triggerProps,
	isMenuOpen,
	isInObjectSideBar = false,
}) => {
	const { formatMessage } = useIntl();

	return (
		<Flex {...triggerProps}>
			<Inline
				xcss={[
					!isInObjectSideBar && aiFloatingContextMenuStyles,
					isInObjectSideBar && aiFloatingContextMenuStylesInObjectSidebar,
				]}
				grow="hug"
				space="space.050"
				alignBlock="center"
			>
				<ExitingPersistence appear exitThenEnter>
					{dynamicActionText && (
						<>
							<FadeIn entranceDirection="right" exitDirection="right" duration={250}>
								{(props) => (
									<div {...props} aria-modal>
										<Button
											appearance="subtle"
											spacing="compact"
											onClick={() => onDynamicActionClick?.()}
											isSelected={isDynamicActionSelected}
										>
											<Box xcss={textColorStyles}>{dynamicActionText}</Box>
										</Button>
									</div>
								)}
							</FadeIn>
							<Box
								xcss={[
									!isInObjectSideBar && dividerStyles,
									isInObjectSideBar && dividerStylesInObjectSidebar,
								]}
							/>
						</>
					)}
				</ExitingPersistence>
				<IconButton
					icon={AtlassianIntelligenceLogoDark}
					label={formatMessage(i18n.logoAltText)}
					onClick={() => onMenuButtonClick?.()}
					appearance="subtle"
					isSelected={isMenuOpen}
					shape={isInObjectSideBar ? 'circle' : 'default'}
				/>
			</Inline>
		</Flex>
	);
};
