import { styled } from '@compiled/react';
import React, { forwardRef } from 'react';

import type { PopupComponentProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		outline: 'none',
	},
	backgroundColor: `${token('elevation.surface.raised', '#FFFFFF')}`,
	border: `.5px solid ${token('color.border', '#DFE1E6')}`,
	borderRadius: '12px',
	boxShadow: `${token(
		'elevation.shadow.overlay',
		'0px 0px 1px 0px #091E424F, 0px 18px 28px 0px #091E4226',
	)}`,
	width: '345px',
});

export const AIFloatingContextMenuPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => {
		return (
			<PopupWrapper {...props} ref={ref} role="dialog">
				{children}
			</PopupWrapper>
		);
	},
);
