import { useContext } from 'react';
import type { ApolloError } from 'apollo-client';

import { CommentCountsContext } from '@confluence/comment-context';

import { PopupStateTypes } from '../popupStateTypes';

import { useDocumentScrollHeight } from './useDocumentScrollHeight';
import { useDynamicSplitButton } from './useDynamicSplitButton';
import { CatchupContentType } from '../__types__/CatchupLastViewedTimeQuery';

type UseDynamicAIContextReturn = {
	dynamicActionTarget:
		| PopupStateTypes.Comments
		| PopupStateTypes.PageCatchup
		| PopupStateTypes.QuickSummary
		| null;
	queryError?: ApolloError;
};

export const useDynamicAIContext = (
	contentId: string,
	contentType: string,
	lastModifiedDate: string,
	pageCommentsSectionInView: boolean,
): UseDynamicAIContextReturn => {
	const { scrollY } = useDocumentScrollHeight();
	const { unresolvedInlineCommentsCount, footerCommentsCount } = useContext(CommentCountsContext);

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;
	const { showCatchupAsDefault, queryError } = useDynamicSplitButton(
		contentId,
		contentTypeEnum,
		lastModifiedDate,
	);

	if (pageCommentsSectionInView && (unresolvedInlineCommentsCount > 0 || footerCommentsCount > 0))
		return { dynamicActionTarget: PopupStateTypes.Comments };

	if (showCatchupAsDefault && scrollY < 400) {
		return { dynamicActionTarget: PopupStateTypes.PageCatchup };
	}

	if (scrollY < 400) return { dynamicActionTarget: PopupStateTypes.QuickSummary, queryError };

	return { dynamicActionTarget: null, queryError };
};
