import type { FC } from 'react';
import React, { memo } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import {
	CommentsSummarySource,
	FooterCommentsSummaryCombined,
} from '@confluence/ai-comments-summary';
import { PageCatchupComponent } from '@confluence/page-catchup';
import { QuickSummaryStreamingComponent } from '@confluence/quick-summary';
import { BulkIssueCreate } from '@confluence/ai-content-actions';
import { fg } from '@confluence/feature-gating';

import { AIFloatingContextMenuGroup } from './AIFloatingContextMenuGroup';
import { PopupStateTypes } from './popupStateTypes';

const FeatureContentWidthStyles = xcss({
	width: '500px',
});

type AIFloatingContextMenuPopupContentProps = {
	popupState: PopupStateTypes;
	contentId: string;
	contentType: string;
	spaceKey: string;
	setIsOpen: (state: boolean) => void;
	setPopupState: (popupKey: PopupStateTypes) => void;
	lastModifiedDate: string;
	source: string;
	onClose: () => void;
};

export const AIFloatingContextMenuPopupContent: FC<AIFloatingContextMenuPopupContentProps> = memo(
	({
		popupState,
		contentId,
		contentType,
		spaceKey,
		lastModifiedDate,
		source,
		onClose,
		setIsOpen,
		setPopupState,
	}) => {
		const renderContent = () => {
			switch (popupState) {
				case PopupStateTypes.Comments:
					return (
						<FooterCommentsSummaryCombined
							contentId={contentId}
							contentType={contentType}
							source={CommentsSummarySource.Header}
							spaceKey={spaceKey}
						/>
					);

				case PopupStateTypes.PageCatchup:
					return (
						<PageCatchupComponent
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
						/>
					);
				case PopupStateTypes.QuickSummary:
					return (
						<QuickSummaryStreamingComponent
							contentId={contentId}
							spaceKey={spaceKey}
							contentType={contentType}
							lastModifiedDate={lastModifiedDate}
							setIsOpen={onClose}
							source={source}
							contentPath={window.location.pathname}
						/>
					);
				case PopupStateTypes.BulkIssueCreate:
					return fg('confluence_ai_bulk_issue_creation') ? null : (
						<BulkIssueCreate contentId={contentId} />
					);
				default:
					return (
						<AIFloatingContextMenuGroup
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
							setPopupState={setPopupState}
							setIsOpen={setIsOpen}
						/>
					);
			}
		};

		return (
			<Box xcss={[popupState !== PopupStateTypes.Menu && FeatureContentWidthStyles]}>
				{renderContent()}
			</Box>
		);
	},
);
