/** @jsx jsx */
/** @jsxFrag */
import React, { useCallback, useState, type FC } from 'react';
import { useIntl } from 'react-intl-next';
import { css, jsx } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { CommentCountsProvider } from '@confluence/comment-context';
import { CustomPopupContainer } from '@confluence/quick-summary';
import { useSessionData } from '@confluence/session-data';
import { useQuickstartEligible, useQuickstartState } from '@confluence/onboarding-quickstart';

import { ContextualButtonPulse } from './platform-ui/ContextualButtonPulse';
import { useDynamicAIContext } from './hooks/useDynamicAIContext';
import { useAIFloatingButtonNudge } from './hooks/useAIFloatingButtonNudge';
import { AIFloatingSplitButton } from './platform-ui/AIFloatingSplitButton';
import { AIFloatingContextMenuPopupContainer } from './AIFloatingContextMenuPopupContainer';
import { i18n as i18nDynamicAction } from './AIFloatingContextMenuGroup';
import { AIFloatingContextMenuPopupContent } from './AIFloatingContextMenuPopupContent';
import { PopupStateTypes } from './popupStateTypes';

const absoluteWrapperStyles = xcss({
	display: 'flex',
	position: 'absolute',
	right: 'space.150',
	bottom: 'space.0',
});

const FloatingContextMenuStyles = css({
	position: 'fixed',
	bottom: token('space.600', '48px'),
	zIndex: '10',
	transform: 'translateX(-100%)',
});

type AIFloatingContextMenuProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	lastModifiedDate?: string | null;
	pageCommentsSectionInView?: boolean;
	isInObjectSideBar?: boolean;
};

const dynamicActionTextMap = {
	[PopupStateTypes.QuickSummary]: i18nDynamicAction.summarizeSectionLabel,
	[PopupStateTypes.Comments]: i18nDynamicAction.inlineCommentsMenuItemLabel,
	[PopupStateTypes.PageCatchup]: i18nDynamicAction.pageCatchupMenuItemLabel,
	[PopupStateTypes.AIPresentationGeneration]:
		i18nDynamicAction.aiPresentationGenerationMenuItemLabel,
	[PopupStateTypes.BulkIssueCreate]: i18nDynamicAction.bulkIssueCreateMenuItemLabel,
};

const AIFloatingContextMenuWrapped: FC<AIFloatingContextMenuProps> = ({
	contentId,
	contentType,
	spaceKey,
	lastModifiedDate = '',
	pageCommentsSectionInView = false,
	isInObjectSideBar = false,
}) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [popupState, setPopupState] = useState<PopupStateTypes>(PopupStateTypes.Menu);
	const { dynamicActionTarget, queryError } = useDynamicAIContext(
		contentId,
		contentType,
		lastModifiedDate ?? '',
		pageCommentsSectionInView,
	);
	const { pulseCount } = useAIFloatingButtonNudge(contentId, contentType, lastModifiedDate ?? '');

	const { isDismissed } = useQuickstartState();
	const { isQuickstartEligible } = useQuickstartEligible();

	const isQuickStartPillShowing = !isDismissed && isQuickstartEligible();

	const isDynamicActionSelected = isOpen && popupState !== PopupStateTypes.Menu;

	const getDynamicActionText = () => {
		if (dynamicActionTarget && !isOpen) {
			return intl.formatMessage(dynamicActionTextMap[dynamicActionTarget]);
		} else if (isOpen && popupState !== PopupStateTypes.Menu) {
			return intl.formatMessage(dynamicActionTextMap[popupState]);
		} else {
			return undefined;
		}
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		setPopupState(PopupStateTypes.Menu);
	}, []);

	const onDynamicActionClick = useCallback(() => {
		if (isOpen) {
			onClose();
		} else if (dynamicActionTarget) {
			setIsOpen(true);
			setPopupState(dynamicActionTarget);
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiDynamicActionButton',
				attributes: {
					contentId,
					dynamicActionTarget,
					toggleClose: isOpen,
				},
			},
		}).fire();
	}, [contentId, createAnalyticsEvent, isOpen, onClose, dynamicActionTarget]);

	const onMenuButtonClick = useCallback(() => {
		if (isOpen && popupState !== PopupStateTypes.Menu) {
			setPopupState(PopupStateTypes.Menu);
		} else if (isOpen) {
			onClose();
		} else {
			setIsOpen(true);
		}
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiActionMenuButton',
				attributes: {
					contentId,
					edition,
					toggleClose: isOpen,
				},
			},
		}).fire();
	}, [contentId, createAnalyticsEvent, edition, isOpen, onClose, popupState]);

	const popupComponent = () => {
		if (popupState === PopupStateTypes.Menu) {
			return AIFloatingContextMenuPopupContainer;
		}

		return CustomPopupContainer;
	};

	if (isQuickStartPillShowing) {
		return null;
	}

	return (
		<>
			{queryError && <ErrorDisplay error={queryError} />}
			<Popup
				isOpen={isOpen}
				onClose={onClose}
				offset={[0, 6]}
				content={() => (
					<AIFloatingContextMenuPopupContent
						popupState={popupState}
						contentId={contentId}
						contentType={contentType}
						spaceKey={spaceKey}
						lastModifiedDate={lastModifiedDate ?? ''}
						source="view-page"
						onClose={onClose}
						setPopupState={setPopupState}
						setIsOpen={setIsOpen}
					/>
				)}
				shouldRenderToParent
				placement="top-end"
				popupComponent={popupComponent()}
				trigger={(triggerProps) => (
					<ContextualButtonPulse pulseCount={pulseCount} isInObjectSideBar={isInObjectSideBar}>
						<AIFloatingSplitButton
							isDynamicActionSelected={isDynamicActionSelected}
							triggerProps={triggerProps}
							onMenuButtonClick={onMenuButtonClick}
							onDynamicActionClick={onDynamicActionClick}
							dynamicActionText={getDynamicActionText()}
							isMenuOpen={isOpen && popupState === PopupStateTypes.Menu}
							isInObjectSideBar={isInObjectSideBar}
						/>
					</ContextualButtonPulse>
				)}
			/>
		</>
	);
};

export const AIFloatingContextMenu = (props: AIFloatingContextMenuProps) => {
	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<CommentCountsProvider contentId={props.contentId}>
				{props.isInObjectSideBar ? (
					<AIFloatingContextMenuWrapped {...props} />
				) : (
					<Box xcss={absoluteWrapperStyles}>
						<div css={[FloatingContextMenuStyles]}>
							<AIFloatingContextMenuWrapped {...props} />
						</div>
					</Box>
				)}
			</CommentCountsProvider>
		</ErrorBoundary>
	);
};
