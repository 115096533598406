import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { fg } from '@confluence/feature-gating';

export type UseDocumentScrollHeightReturn = {
	scrollY: number;
};

export const useDocumentScrollHeight = (): UseDocumentScrollHeightReturn => {
	const newViewPageContainer = document.getElementById('AkMainContent');

	const [scrollHeightAttributes, setScrollHeightAttributes] =
		useState<UseDocumentScrollHeightReturn>({
			scrollY:
				newViewPageContainer && fg('confluence_nav_4')
					? newViewPageContainer.scrollTop
					: window.scrollY,
		});

	const handleScroll = useCallback(() => {
		setScrollHeightAttributes({
			scrollY:
				newViewPageContainer && fg('confluence_nav_4')
					? newViewPageContainer.scrollTop
					: window.scrollY,
		});
	}, [setScrollHeightAttributes, newViewPageContainer]);

	useEffect(() => {
		const debounceScroll = debounce(handleScroll, 250);
		fg('confluence_nav_4')
			? newViewPageContainer?.addEventListener('scroll', debounceScroll)
			: document.addEventListener('scroll', debounceScroll);

		return () => {
			fg('confluence_nav_4')
				? newViewPageContainer?.removeEventListener('scroll', debounceScroll)
				: document.removeEventListener('scroll', debounceScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return scrollHeightAttributes;
};
