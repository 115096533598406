import { useEffect, useRef, useState } from 'react';

import {
	useOnboardingState,
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_INSIGHTS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

import { useDynamicSplitButton } from './useDynamicSplitButton';
import { CatchupContentType } from '../__types__/CatchupLastViewedTimeQuery';

type UseAIFloatingButtonNudgeReturn = {
	pulseCount: number;
};

export const useAIFloatingButtonNudge = (
	contentId: string,
	contentType: string,
	lastModifiedDate: string,
): UseAIFloatingButtonNudgeReturn => {
	const { setOnboardingState } = useOnboardingState();
	const [pulseCount, setPulseCount] = useState(0);
	const hasLoaded = useRef(false);

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;

	const { showCatchupAsDefault, queryLoading: dynamicSplitButtonLoading } = useDynamicSplitButton(
		contentId,
		contentTypeEnum,
		lastModifiedDate,
	);

	// Ignoring error state as we will just not show a pulse if this errors
	const { data: onboardingStateData, loading: onboardingStateLoading } = useGetOnboardingState(
		ONBOARDING_INSIGHTS.CONTEXTUAL_FLOATING_BUTTON_NUDGE,
	);

	useEffect(() => {
		if (!dynamicSplitButtonLoading && !onboardingStateLoading && !hasLoaded.current) {
			// We only want this to execute once and only once when both hooks are finished loading
			hasLoaded.current = true;

			const { hasContextualFloatingButtonBeenSeen } = deserializeState(onboardingStateData);

			if (onboardingStateData != null && !hasContextualFloatingButtonBeenSeen) {
				setOnboardingState({
					key: ONBOARDING_INSIGHTS.CONTEXTUAL_FLOATING_BUTTON_NUDGE,
					value: 'true',
				});
				setPulseCount(2);
			} else if (showCatchupAsDefault) {
				setPulseCount(1);
			}
		}
	}, [
		dynamicSplitButtonLoading,
		onboardingStateData,
		onboardingStateLoading,
		setPulseCount,
		setOnboardingState,
		showCatchupAsDefault,
	]);

	return { pulseCount };
};
